.MuiPickersToolbar-root {
  .MuiDateTimePickerToolbar-timeContainer {
    align-items: center;
  }
  .MuiTypography-overline {
    font-size: inherit;
    color: #000000;
    text-transform: lowercase;
    &::first-letter {
      text-transform: uppercase;
    }
  }
}

.MuiCalendarPicker-root {
  .Mui-selected {
    background-color: #2b2aec !important;
  }
}
