.startup-screen {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 60px;

  .text {
    flex: none;
    padding: 16px;
    text-align: center;
    height: 30px;
    white-space: pre;
  }
}

.Toastify__toast-body {
  padding: 0;
}